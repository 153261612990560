export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "resourceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources List"])},
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Air "])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])}
      },
      "zh": {
        "resourceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包列表"])},
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未下载"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下 载"])}
      }
    }
  })
}
